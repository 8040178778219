import { React, useState } from 'react';
import AlphabetButton from './alphabetButton';
import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/solid";

const alphabets = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

const PaginateAlphabet = ({ setAlphabet }) => {
    const [page, setPage] = useState(0);

    const getButtonIcon = (name) => {
        if (name.startsWith("ChevronLeftIcon")) {
            return (
                <ChevronLeftIcon className="h-4 w-5" />
            )
        } else if (name.startsWith("ChevronRightIcon")) {
            return (
                <ChevronRightIcon className="h-4 w-5" />
            )
        } else if (name.startsWith("ChevronDoubleLeftIcon")) {
            return (
                <ChevronDoubleLeftIcon className="h-4 w-5" />
            )
        } else if (name.startsWith("ChevronDoubleRightIcon")) {
            return (
                <ChevronDoubleRightIcon className="h-4 w-5" />
            )
        }

        return name;
    }

    const handleButtonClick = (newPage) => {
        setPage(newPage);
        setAlphabet(alphabets[newPage]);
    }

    return (
        <div className="p-5 flex flex-wrap justify-center text-gray-600">
            <AlphabetButton onClick={() => handleButtonClick(0)} disabled={page <= 0}>{getButtonIcon("ChevronDoubleLeftIcon")}</AlphabetButton>
            <AlphabetButton onClick={() => handleButtonClick(page-1)} disabled={page <= 0}>{getButtonIcon("ChevronLeftIcon")}</AlphabetButton>
            { alphabets.length-1 - page < 1 && page - 4 > 0 && <AlphabetButton onClick={() => handleButtonClick(page - 4)}>{alphabets[page - 4]}</AlphabetButton> }
            { alphabets.length-1 - page < 2 && page - 3 > 0 && <AlphabetButton onClick={() => handleButtonClick(page - 3)}>{alphabets[page - 3]}</AlphabetButton> }
            { alphabets.length-1 && page - 2 > 0 && <AlphabetButton onClick={() => handleButtonClick(page - 2)}>{alphabets[page - 2]}</AlphabetButton> }
            { alphabets.length-1 && page - 1 > 0 && <AlphabetButton onClick={() => handleButtonClick(page - 1)}>{alphabets[page - 1]}</AlphabetButton> }
            <AlphabetButton active="true">{alphabets[page]}</AlphabetButton>
            { page + 1 <= alphabets.length-1 && <AlphabetButton onClick={() => handleButtonClick(page + 1)}>{alphabets[page + 1]}</AlphabetButton> }
            { page + 2 <= alphabets.length-1 && <AlphabetButton onClick={() => handleButtonClick(page + 2)}>{alphabets[page + 2]}</AlphabetButton> }
            { page + 3 <= alphabets.length-1 && page < 3 && <AlphabetButton onClick={() => handleButtonClick(page + 3)}>{alphabets[page + 3]}</AlphabetButton> }
            { page + 4 <= alphabets.length-1 && page < 2 && <AlphabetButton onClick={() => handleButtonClick(page + 4)}>{alphabets[page + 4]}</AlphabetButton> }
            <AlphabetButton onClick={() => handleButtonClick(page+1)} disabled={page >= alphabets.length-1}>{getButtonIcon("ChevronRightIcon")}</AlphabetButton>
            <AlphabetButton onClick={() => handleButtonClick(alphabets.length-1)} disabled={page >= alphabets.length-1}>{getButtonIcon("ChevronDoubleRightIcon")}</AlphabetButton>
        </div>
    );
};

export default PaginateAlphabet;
