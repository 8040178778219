import { getPronunciation } from './dictionary-service';

export const playAudio = async (word) => {
    if (!word) return;

    const pronunciationStream = await getPronunciation(word);
    const readableStream = await getReadableStream(pronunciationStream);
    const responseStream = new Response(readableStream);
    const blob = await responseStream.blob();
    const url = URL.createObjectURL(blob);
    window.audio = new Audio();
    window.audio.src = url;
    window.audio.play();
};

const getReadableStream = async (response) => {
    // Source: https://developer.mozilla.org/en-US/docs/Web/API/Streams_API/Using_readable_streams
    const reader = response.body.getReader();
    return new ReadableStream({
        async start(controller) {
            return getChunks();
            function getChunks() {
                return reader.read().then(({ done, value }) => {
                    // When no more data needs to be consumed, close the stream
                    if (done) {
                        controller.close();
                        reader.releaseLock();
                        return;
                    }
                    // Enqueue the next data chunk into our target stream
                    controller.enqueue(value);
                    return getChunks();
                });
            }
        },
    });
};
