import { React, Fragment, useState } from "react";
import { DialogHeader, DialogBody, IconButton, Typography } from "@material-tailwind/react";
import Dialog from "@mui/material/Dialog";
import { submitFeedback } from "../../services/dictionary-service";
import { Spinner } from "../loading-spinner/spinner";

export const FeedbackDialog = ( {open, setOpen} ) => {

    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [feedbacksubmittedResult, setFeedbackSubmittedResult] = useState(false);

    const submitUserFeedback = async (formData) => {
        setLoading(true);
        formData.preventDefault();

        const feedback = {
            name: formData.target.elements.name.value,
            email: formData.target.elements.email.value,
            message: formData.target.elements.message.value
        };

        var result = await submitFeedback(feedback);
        setLoading(false);
        setFeedbackSubmittedResult(result);
        setHasError(!result);
    };

    const handleClose = async () => {
        setOpen(false)
        setFeedbackSubmittedResult(false);
        setHasError(false);
    };

  return (
    <Fragment>
      <Dialog size="md" open={open} onClose={handleClose}>
        <DialogHeader className="justify-between">
          <Typography variant="h5" color="blue-gray">
            {!feedbacksubmittedResult && !hasError && <span>We'd love to hear from you</span>}
            {feedbacksubmittedResult && !hasError && <span>Thank you!</span>}
            {hasError && <span>Error</span>}
          </Typography>
          <IconButton
            color="blue-gray"
            size="sm"
            variant="text"
            onClick={handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </DialogHeader>
        <DialogBody divider>
          <>
            {!feedbacksubmittedResult && !hasError &&
                <form className="max-w-sm mx-auto" onSubmit={submitUserFeedback}>
                <div className="mb-5">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your name</label>
                    <input type="name" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                </div>
                <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                    <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="someone@example.com" />
                </div>
                <div className="mb-5">
                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message<span className="text-red-600">*</span></label>
                    <textarea name="message" id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Leave a comment..." required></textarea>
                </div>
                <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    {!loading && <span>Submit</span>}
                    {loading && <Spinner size={20} color="inherit" />}
                </button>
            </form>}
            {feedbacksubmittedResult && !hasError &&
                <p>Your feedback has been submitted.</p>
            }
            {hasError && 
                <>
                    <p className="text-red-600">Your feedback cannot be submitted. Please try again later.</p>
                    <button onClick={handleClose} className="mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Close
                    </button>
                </>
            }
          </>
        </DialogBody>
      </Dialog>
    </Fragment>
  );
}
