import Banner from '../banner/Banner';
import MainNavigation from './MainNavigation';

function ContentWrapper(props) {
    return (
        <div>
            <MainNavigation />
            <Banner/>
            <main className="p-4 sm:p-10">{props.children}</main>
        </div>
    )
}

export default ContentWrapper;