import React, { useState } from "react";
import * as constants from "./constants";
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ArrowsRightLeftIcon } from "@heroicons/react/24/solid";
import { getMatchingResults } from '../../services/dictionary-service';

export const SearchBar = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState('');
    const [open, setOpen] = useState(false);
    const [searchMatchList, sestSearchMatchList] = useState([]);
    const [isEnglishChecked, setIsEnglishChecked]= useState(false);
    const [noResultsText, setNoResultsText] = useState(constants.sesothoNoResultsText);
    const [searchPlaceholder, setSearchPlaceholder] = useState(constants.searchSesothoPlaceholder);
    const [searchLanguageText, setSearchLanguageText]= useState(constants.englishSearchLanguageText);

    const fetchQuery = async (value) => {
        const matchingResults = await getMatchingResults(value, isEnglishChecked);
        sestSearchMatchList(matchingResults ? matchingResults.map((x, index) => { x.id = index+1; return x; }) : []);
    }

    const search = (event) => {
        if ((event.type === 'keyup' && event.key !== 'Enter') || query.length === 0) return;
        setOpen(false);
        if (query.length > 0) {
            navigate(`/search-result/${query}`);
        }
    }

    const handleInputChange = (value) => {
        setQuery(value)
        fetchQuery(value)
    }

    const isEnglishCheckedHandler = () => {
        setIsEnglishChecked(!isEnglishChecked);
        setQuery('');
        sestSearchMatchList([]);
        setSearchPlaceholder(isEnglishChecked ? constants.searchSesothoPlaceholder : constants.searchEnglishPlaceholder);
        setSearchLanguageText(isEnglishChecked ? constants.englishSearchLanguageText : constants.sesothoSearchLanguageText);
        setNoResultsText(isEnglishChecked ? constants.englishNoResultsText : constants.sesothoNoResultsText);
    }

    return (
        <div className="md:mb-7 mt-7 px-30 md:w-1/2 mx-auto">
            <div className="flex justify-center mb-4">
                <button className='rounded-lg p-2 border-indigo-500 border-r-2 bg-white hover:hg-indigo-100 transition duration-150 ease-in-out' onClick={isEnglishCheckedHandler}>
                    <div className="grid grid-cols-4 gap-2">
                        <div className="col-span-3 pl-4">
                                {searchLanguageText}
                        </div>
                        <div>
                            <ArrowsRightLeftIcon className="h-4 w-5 mt-0.5 absolute flex" />
                        </div>
                    </div>
                </button>
            </div>

            <div className="relative w-full">
                <Autocomplete
                    open={open}
                    onOpen={() => setOpen(true)}
                    onFocus={() => setOpen(true)}
                    onBlur={(e) => { if (!e.currentTarget.contains(e.relatedTarget)) setOpen(false); }}
                    className="w-11/12 search-box bg-white"
                    disablePortal
                    id="search-combo-box"
                    getOptionLabel={(option) => option.word}
                    filterOptions={(x) => x}
                    sx={{ '& fieldset': { borderTopLeftRadius: 33, borderBottomLeftRadius: 33 }}}
                    options={searchMatchList}
                    autoComplete
                    includeInputInList
                    onInputChange={(e, newValue) => handleInputChange(newValue)}
                    filterSelectedOptions
                    noOptionsText={noResultsText}
                    // noResultsText={noResultsText}
                    renderOption={(props, option) => (
                        <li component="li" {...props} key={option.id}>
                          {isEnglishChecked ? `${option.word} (${option.matchText})` : option.word}
                        </li>
                      )}
                    renderInput={(params) => <TextField {...params} 
                    placeholder={searchPlaceholder}
                    value={query}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onKeyUp={(e) => search(e)} />}
                />
                <button onClick={(e) => search(e)} className="absolute top-0 right-0 p-2.5 pl-6 h-full w-16 search-button bg-white">
                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                    <span className="sr-only">Search</span>
                </button>
            </div>
        </div>
    );
}
