import { Tooltip } from "@material-tailwind/react";
import { playAudio } from '../../services/audio-service';
import { Typography } from "@material-tailwind/react";
import { SpeakerWaveIcon, PhotoIcon } from "@heroicons/react/24/solid";
import { useState, Fragment, useEffect } from "react";
import { ImagesDialog } from '../images-dialog/images-dialog'
import * as constants from "./constants";
import { getImages } from '../../services/dictionary-service';
import { Spinner } from '../../components/loading-spinner/spinner';
import parse from 'html-react-parser';

export const SearchResults = ({ definitions }) => {
    const [showImages, setShowImages] = useState(-1);
    const [isLoadingImages, setIsLoadingImages] = useState(true);
    const [isLoadingPronunciation, setIsLoadingPronunciation] = useState(false);
    const [definitionsWithImages, setDefinitionsWithImages] = useState(definitions);

    const playPronunciation = async () => {
        setIsLoadingPronunciation(true);
        try {
            const word = definitions[0].word.toLowerCase();
            await playAudio(word);
        }
        catch (error) {
            throw error;
        }
        finally {
            setIsLoadingPronunciation(false);
        }
    };

    const displaySmallLoadingSpinner = () => {
        return <div className="pl-4 pt-3"><Spinner size={20} /></div>;
    }

    const displayIcon = (icon, typographyClass) => {
        return (
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className={typographyClass}
            >
                {getIcon(icon)}
            </Typography>
        )
    }

    const getIcon = (icon) => {
        const iconClass = "h-s w-6 pt-2 mt-0.5 absolute flex";
        switch(icon) {
            case 'speaker-wave':
                return <SpeakerWaveIcon className={iconClass} />;
            case 'photo':
                return <PhotoIcon className={iconClass} />;
            default:
                return <></>;
        }
    }

    const formatText = (text) => {
        if (!!text && text.includes("<div")) {
            text = text.replaceAll("''", "\"");
            text = text.replace("</div>.", "</div>");
            return parse(text);
        }

        return text;
    }
    
    useEffect(() => {
        async function fetchImages() {
            let definitionsObj = JSON.parse(JSON.stringify(definitions));
            setIsLoadingImages(true);
            let hasError = false;
    
            for await (let element of definitionsObj) {
                try {
                    if (!!element.english && !element.isDerogatory) {
                        element.images = await getImages(element.english);
                    }
                } catch (error) {
                    hasError = true;
                    throw error;
                } finally {
                    if (!hasError) setDefinitionsWithImages(definitionsObj);
                    setIsLoadingImages(false);
                }
            }
        };

        fetchImages();
    }, [definitions]);

    return (
        <div>
            {
                definitionsWithImages?.map((result, index) => {
                    return (
                        <div className='mx-0 sm:mx-10' key={result.id}>
                            <div className="flex items-stretch border-b border-gray-400">
                                <h2 className='text-4xl'>{result.word.toLowerCase()}</h2>
                                {!index && 
                                    <Fragment>
                                        <div className="cursor-pointer" onClick={playPronunciation}>
                                            {isLoadingPronunciation && displaySmallLoadingSpinner()}
                                            {!isLoadingPronunciation && displayIcon("speaker-wave", "pl-6 font-normal")}
                                        </div>
                                    </Fragment>
                                }
                                {!!(result?.images?.length) && 
                                    <h2 className="px-10 cursor-pointer" onClick={() => setShowImages(index)}>
                                        {isLoadingImages && displaySmallLoadingSpinner()}
                                        {!isLoadingImages && displayIcon("photo", "pl-2 font-normal")}
                                    </h2>
                                }
                                <ImagesDialog index={index} showImages={showImages} setShowImages={setShowImages} groupedImages={{word: result.word, images: result.images, alt: result.english}} />
                            </div>
                            <h2 className='text-2xl text-gray-600'>{result.pronunciation} {result.english}</h2>
                            {!!result.isDiminutive && <span className="px-2 bg-gray-400 rounded-lg">Nyenyefatso</span>}
                            <div className='my-5 text-base text-black'>
                                {formatText(result.definition)}
                                {!!result.isUnsatisfactory && 
                                    <Tooltip content={constants.isUnsatisfactoryTooltipText} placement="right">
                                        <span className="cursor-pointer"> ??</span>
                                    </Tooltip>}
                                {!!result.source && <span className='text-sm'> ({result.source})</span>}
                            </div>
                            {!!result.example && <p><span className="font-bold">Mohlala:</span> {result.example}</p>}
                            {!!result.plural && <p><span className="font-bold">Bongata:</span> {result.plural}</p>}
                            {!!result.related && <p><span className="font-bold">Mantsoe mabapa:</span> {result.related}</p>}
                            {!!result.lookFor && <p><span className="font-bold">Sheba:</span> {result.lookFor}</p>}
                            {!!result.compare && <p><span className="font-bold">Bapisa:</span> {result.compare}</p>}
                            {!!result.pastTense && <p><span className="font-bold">Bokgale:</span> {result.pastTense}</p>}
                            {!!result.noun && <p><span className="font-bold">Lereho:</span> {result.noun}</p>}
                            {!!result.interrogativePronoun && <p><span className="font-bold">Lebotsa:</span> {result.interrogativePronoun }</p>}
                            {!!result.demonstrativePronoun && <p><span className="font-bold">Lesupa:</span> {result.demonstrativePronoun }</p>}
                            {!!result.verb && <p><span className="font-bold">Leetsi:</span> {result.verb }</p>}
                            {!!result.proverbs && <p><span className="font-bold">Maele/maelana/sekapuo:</span> {result.proverbs }</p>}
                            {!!result.isDerogatory && <p className='text-red-500'>Lentsoe le ronehang/seka tlhapa.</p>}
                            {!!result.southAfricanSesotho && <p><span className="font-bold">Sesotho sa Afrika Borwa:</span> {result.southAfricanSesotho }</p>}
                            {!!result.lesothoSesotho && <p><span className="font-bold">Sesotho sa Lesotho:</span> {result.lesothoSesotho }</p>}
                            {!!result.noteWell && <p><span className="font-bold">Temoso:</span> {result.noteWell }</p>}
                            {!!result.notice && <p><span className="font-bold">Thlokomediso:</span> {result.notice }</p>}
                            {!!result.opinion && <p><span className="font-bold">Maikutlo a mongodi:</span> {result.opinion }</p>}
                        </div>
                    )
                })
            }
        </div>
    )
}
