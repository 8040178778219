import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getWordOfTheDay } from '../services/dictionary-service';

function HomePage() {
    const navigate = useNavigate();
    const [wordOfTheDay, setWordOfTheDay] = useState('');

    const navigateToSearch = () => {
        navigate(`/search-result/${wordOfTheDay}`);
    };

    const fetchWordOfTheDay = async () => {
        const wotd = await getWordOfTheDay();
        setWordOfTheDay(wotd.word);
    }

    useEffect(() => {
        fetchWordOfTheDay();
    }, []);

    return <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
        <div>
            {!!wordOfTheDay && 
                <div className="border-2 border-blue-500 rounded-lg text-center">
                    <p>Lentsoe la letsatsi</p>
                    <p className="text-2xl font-bold cursor-pointer hover:font-extrabold"
                        onClick={navigateToSearch}>{wordOfTheDay}</p>
                </div>
            }
        </div>
        <div className="col-span-1 sm:col-span-3 px-0 sm:px-16">
            <p className="text-4xl font-bold">Ka Sesotho</p>
            <p className="pt-4">
                Sesotho ke enngwe ya ‘puo tse kgolo ka ho fetisisa Afrika Borwa. Ke puo ya tseding tsenang le mantsoe a mangata ka ho fetisisa Afrika e ka Borwa. 
                Hona ha ho makatse hobane bongata ba Basotho ba mahareng a Afrika Borwa (Lesotho, Free State, Qwaqwa, le dikarolong tseding tsa Northen Cape). 
            </p>
            <p className="pt-4">
                Hofeta moo, o fumana Basotho dibakeng tse tshoanang le Kwa-Zulu; bao Sesotho sa bona se itshetlehileng ho SeZulu (Makholokoe) joaloka ba ha Molefe 
                le ba ha Moloi. O fumana Basotho ba mabapa kapa ba phelang Eastern Cape joaloka boMatatiele; bao Sesotho sa bona se itshetlehileng ho SeQhotsa (isiXhosa). 
            </p>
            <p className="pt-4">
                O fumana Basotho Limpopo province, Mpumalanga provice, North West province bao Sesotho sa bona se itshetlehileng dibakeng tseo. Historing (nalaneng), re 
                utloa ka bo-Sebetoane ba ileng ba tsamaya le ho hahlaola dibakeng tse tshoanang le bo-Zambia le bo-Zimbabwe mme ba amarela puo ya bona bakeng tseno. 
                Ke kahoo re fumanang diphororo tse kgolo ka ho fetisisa Afrika tseo lebitso la Sesotho tsona eleng Mosi-wa-thunya (Victoria falls).
            </p>
        </div>
    </div>
}

export default HomePage;