import { React, Fragment } from "react";
import { DialogHeader, DialogBody, DialogFooter, IconButton, Typography } from "@material-tailwind/react";
import Dialog from "@mui/material/Dialog";

export const ImagesDialog = ( {index, showImages, setShowImages, groupedImages} ) => {
  return (
    <Fragment>
      <Dialog size="md" open={index === showImages} onClose={() => setShowImages(-1)}>
        <DialogHeader className="justify-between">
          <Typography variant="h5" color="blue-gray">
            {groupedImages.word.toLowerCase()} <span className="text-sm italic font-thin">{groupedImages.alt}</span>
          </Typography>
          <IconButton
            color="blue-gray"
            size="sm"
            variant="text"
            onClick={() => setShowImages(-1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </DialogHeader>
        <DialogBody divider>
          <>
            {
              groupedImages?.images?.map((tagGroup, index) => {
                return (
                  <div key={index}>
                      <p>{tagGroup.tag}</p>
                      <div className="grid grid-cols-3 md:grid-cols-3 gap-4">
                        {
                          tagGroup?.imageUrls?.map((url, index) => {
                            return (
                              <div key={index}>
                                <img className="h-auto max-w-full rounded-lg" src={url} alt={groupedImages.alt} />
                              </div>
                            )
                          })
                        }
                      </div>
                  </div>
                )
              })
            }
          </>
        </DialogBody>
        <DialogFooter>
          <p className="text-black">Photos by <a href="https://unsplash.com/" className="underline hover:italic" target="_blank" rel="noreferrer">Unsplash</a></p>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
}
