import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { Spinner } from '../components/loading-spinner/spinner'
import { getDefinitions } from '../services/dictionary-service';
import { SearchResults } from "../components/search/SearchResults"

function SearchResultPage() {
    const { search } = useParams();
    const [loading, setLoading] = useState(false);
    const [definitions, setDefinitions] = useState([]);

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            const response = await getDefinitions(search);
            setDefinitions(response);
            setLoading(false);
        }
        
        fetchData();
      }, [search]);

    return (
        <>
            {loading && <Spinner />}
            {!loading && <SearchResults definitions={definitions} />}
        </>
    )
}

export default SearchResultPage;