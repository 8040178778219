import { Routes, Route } from 'react-router-dom';

import HomePage from './pages/HomePage';
import DictionaryPage from './pages/DictionaryPage';
import SesothoPage from './pages/AboutUs/SesothoPage';
import WordOfTheDayPage from './pages/WordOfTheDayPage';
import PlatformPage from './pages/AboutUs/PlatformPage';
import SearchResultPage from './pages/SearchResultPage';
import FutureLexPage from './pages/AboutUs/FuturelexPage';
import ContentWrapper from './components/layout/ContentWrapper';

function App() {
  return (
    <>
      <ContentWrapper>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/dictionary" element={<DictionaryPage />} />
          <Route path="/word-of-the-day" element={<WordOfTheDayPage />} />
          <Route path="/about-sesotho" element={<SesothoPage />} />
          <Route path="/sesetho-platform" element={<PlatformPage />} />
          <Route path="/about-futurelex" element={<FutureLexPage />} />
          <Route path="/search-result/:search" element={<SearchResultPage />} />
        </Routes>
        </ContentWrapper>
    </>
  );
}

export default App;
