import { useNavigate } from 'react-router-dom';
import { React, Fragment, useState, useEffect } from 'react';

import Paginate from '../components/pagination/paginate';
import PaginateAlphabet from '../components/pagination/paginateAlphabet';
import { Spinner } from '../components/loading-spinner/spinner'
import { getWordsInAlphabet } from '../services/dictionary-service';

function DictionaryPage(){
    const navigate = useNavigate();
    const [wordsPerPage] = useState(84);
    const [words, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alphabet, setAlphabet] = useState('a');
    const [currentPage, setCurrentPage] = useState(1);

    const navigateToResultPage = (word) => {
        navigate(`/search-result/${word}`);
    };

    const fetchWordsInAlphabet = async (value) => {
        setLoading(true);
        setAlphabet(value);
        setResults(await getWordsInAlphabet(value));
        setCurrentPage(1);
        setLoading(false);
    }

    useEffect(() => {
        fetchWordsInAlphabet(alphabet);
    }, [alphabet]);

    const indexOfLastWord = currentPage * wordsPerPage;
    const indexOfFirstWord = indexOfLastWord - wordsPerPage;
    const currentWords = words?.slice(indexOfFirstWord, indexOfLastWord);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
           setCurrentPage(currentPage - 1);
        }
    };
   
    const nextPage = () => {
        if (currentPage !== Math.ceil(words?.length / wordsPerPage)) {
           setCurrentPage(currentPage + 1);
        }
    };
    
    return (
        <Fragment>
            <PaginateAlphabet setAlphabet={setAlphabet} />
            {loading && <Spinner />}
            {!loading && 
                <div className="grid lg:grid-cols-10 md:grid-cols-6 sm:grid-cols-2 gap-4 mt-4 mb-4">
                    {
                        currentWords?.map((word, index) => {
                            return (
                                <div 
                                    className='cursor-pointer shadow-md border-2 rounded-sm' 
                                    key={index} 
                                    onClick={() => navigateToResultPage(word)}>
                                        {word}
                                </div>
                            )
                        })
                    }
                </div>
            }
            <Paginate
                wordsPerPage={wordsPerPage}
                totalWords={words?.length}
                paginate={paginate}
                previousPage={previousPage}
                currentPage={currentPage}
                nextPage={nextPage}
            />
        </Fragment>
    )
}

export default DictionaryPage;