
export const getMatchingResults = async (searchText, isEnglishChecked) => {
    if (searchText.length === 0) return [];

    const baseUrl = getApiBaseUrl();

    return await fetch(`${baseUrl}Entries/Match?matchText=${searchText}&english=${isEnglishChecked}`)
        .then((response) => response.json())
        .then((results) => {
            return results;
        })
        .catch(error => console.error(error));
};

export const getDefinitions = async (searchText) => {
    if (searchText.length === 0) return [];

    const baseUrl = getApiBaseUrl();

    return await fetch(`${baseUrl}Entries?word=${searchText}`)
        .then((response) => response.json())
        .then((results) => {
            return results;
        })
        .catch(error => console.error(error));
};

export const getPronunciation = async (word) => {
    if (!word) return;

    const baseUrl = getApiBaseUrl();

    return await fetch(`${baseUrl}Entries/Pronunciation?word=${word}`)
        .then((response) => {
            return response;
        })
        .catch(error => console.error(error));
};

export const getImages = async (tags) => {
    const baseUrl = getApiBaseUrl();
    const url = new URL(`${baseUrl}Entries/Images`);
    url.searchParams.append('searchText', tags);

    return await fetch(url)
        .then((response) => response.json())
        .then((result) => {
            return result;
        })
        .catch(error => console.error(error));
}

export const getWordsInAlphabet = async (alphabet) => {
    const baseUrl = getApiBaseUrl();

    return await fetch(`${baseUrl}Entries/Alphabet?alphabet=${alphabet}`)
        .then((response) => response.json())
        .then((result) => {
            return result;
        })
        .catch(error => console.error(error));
}

export const getWordOfTheDay = async () => {
    const baseUrl = getApiBaseUrl();

    return await fetch(`${baseUrl}Entries/WordOfTheDay`)
        .then((response) => response.json())
        .then((result) => {
            return result;
        })
        .catch(error => console.error(error));
}

export const submitFeedback = async (feedback) => {
    const baseUrl = getApiBaseUrl();

    return await fetch(`${baseUrl}Entries/Feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(feedback),
      })
        .then((response) => response.json())
        .then((result) => {
            return result;
        })
        .catch(error => {
            console.error(error);
            return false;
        });
}

const getApiBaseUrl = () => {
    const uiUrl = window.location.origin;
    switch(uiUrl) {
        case 'http://localhost:3000':
            return 'https://localhost:7214/';
        case 'https://salmon-field-0d9fc360f.2.azurestaticapps.net':
            return 'https://dictionary-api.azurewebsites.net/';
        default:
            return 'https://dictionary-api.azurewebsites.net/';
    }
};
