import React from "react";
import { SearchBar } from "../search/SearchBar";
import '../search/Search.css';

function Banner() {
    return (
        <div>
            <section className="block w-full sticky inset-0 z-10">
                <div className="px-20 py-2 bg-indigo-500 square">
                    <div className="flex flex-wrap">
                        <div className="w-full pt-6 mb-10 md:mb-0">
                            <h3 className="mb-1 text-2xl font-bold text-white">
                                <span className="text-green-300">SESOTHO</span>
                                <span className="pl-2">DICTIONARY</span>
                            </h3>
                            <p className="mb-8 md:mb-5 text-sm font-medium text-indigo-100">Etsa hore mantsoe a hao a be le moelelo</p>
                            <SearchBar />
                        </div>
                        <div className="w-full md:w-1/2 flex items-center">
                            <img className="mx-auto h-5" src="artemis-assets/images/chart-folder.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Banner