
function AlphabetButton({ children, ...props }) {
    const getClassName = () => {
      let buttonClass = "inline-block py-3 px-4 mx-px text-center leading-none rounded enabled:hover:bg-gray-100 enabled:focus:bg-gray-100 disabled:opacity-50";
      if (props.active === "true") {
        buttonClass += " bg-blue-100 text-blue-400 border border-blue-400";
      } else {
        buttonClass += " border border-gray-500 text-black";
      }

      if (typeof(children) === "object") {
        buttonClass = buttonClass.replace("px-4", "px-2");
      }

      return buttonClass;
    };

    return (
      <button className={getClassName()} {...props} >{children}</button>
    );
};

export default AlphabetButton;