import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
 
const Paginate = ({ wordsPerPage, totalWords, paginate, previousPage, currentPage, nextPage }) => {
   const pageNumbers = [];
 
   for (let i = 1; i <= Math.ceil(totalWords / wordsPerPage); i++) {
    pageNumbers.push(i);
   }

   const handleChange = (event, value) => {
    if (event.currentTarget.ariaLabel === 'Go to previous page') {
        previousPage();
    }
    else if (event.currentTarget.ariaLabel === 'Go to next page') {
        nextPage();
    }
    else if (event.currentTarget.ariaLabel.startsWith('Go to page')) {
        paginate(value)
    }
  };
 
   return (
    <div className="flex flex-row p-4 justify-center">
        <Stack spacing={2}>
            <Pagination count={pageNumbers.length} variant="outlined" shape="rounded" color='primary' size='large' page={currentPage} onChange={handleChange} />
        </Stack>
    </div>
   );
};
 
export default Paginate;

export const getPaginationClass = (value, currentPage, numberOfPages) => {
    let bgColour = 'bg-indigo-300';
    let hover = 'hover:bg-indigo-500';
    let cellClass = 'px-4 py-2 text-white cursor-pointer';

    if (value === 'Prev' || value === 'Next') hover = '';
    if (value === 'Prev' && !!numberOfPages && currentPage > 1) bgColour = 'bg-indigo-500';
    if (value === 'Next' && !!numberOfPages && currentPage < numberOfPages ) bgColour = 'bg-indigo-500';

    if (value === currentPage) bgColour = 'bg-indigo-500';
    cellClass += ` ${bgColour} ${hover}`.trimEnd();

    if(value === 'a' || value === 'Prev') return `${cellClass} rounded-l-lg`;
    if(value === 'z' || value === 'Next') return `${cellClass} rounded-r-lg`;
    return cellClass;
};
